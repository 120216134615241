import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import LoginButton from "./loginButton"
import LogoutButton from "./logoutButton"

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated ? (
      <div class="profile">
        <img class="profile-pic" src={user.picture} alt={user.name} />
        <p class="profile-name">{user.name}</p>
        <p class="profile-email">{user.email}</p>
        <LogoutButton />
      </div>
    ) : (
      <div class="profile-out">
        Not Logged in <LoginButton />
      </div>
    )
  );
};

export default Profile;