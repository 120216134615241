// custom typefaces
//import "typeface-montserrat"
//import "typeface-merriweather"
require("typeface-montserrat")
require("typeface-merriweather")

//import { Auth0Provider } from "@auth0/auth0-react";
const auth0 = require("@auth0/auth0-react");

const React = require("react")
const Layout = require("./src/components/layout")

// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return (<auth0.Auth0Provider
      domain="lmexper.au.auth0.com"
      clientId="9zSz8LCeqT8v22EoMHc226KJ6qwPABn3"
      redirectUri={window.location.origin}
      audience="https://dev.junk.rocks"
      scope="read:images"
    >{element}</auth0.Auth0Provider>)

}